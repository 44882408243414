<template>
  <div>
    <br /><br />
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="surveyQuestions" v-else>
      <b-container>
        <b-row>
          <b-col md="12">
            <!-- delete question delete model -->
            <b-modal
              id="deleteSurveyQuestion"
              no-close-on-backdrop
              no-close-on-keyboard
              no-close-on-esc
              hide-footer
              hide-header
              centered
              size="md"
            >
              <h6 style="font-size: 18px; font-weight: 600" class="text-danger">
                Deleting this question will affect the already submitted surveys
                & reports. Are you Sure you want to proceed?
              </h6>
              <div class="buttons">
                <b-button
                  size="sm"
                  variant="primary"
                  @click="deleteSurveyQuestionFromServer()"
                  >Proceed</b-button
                >
                <b-button
                  size="sm"
                  variant="danger"
                  class="ml-2"
                  @click="$bvModal.hide('deleteSurveyQuestion')"
                  >Cancel</b-button
                >
              </div>
            </b-modal>

            <b-button
              variant="white"
              class="text-primary"
              v-if="surveyQuestions.length == 0"
              @click="surveyQuestionsForm = true && prepareSurveyQuestions()"
              ><i class="fas fa-plus-square fa-2x"></i
            ></b-button>
            <b-form
              @submit.prevent="prepareSurveyQuestionsForAPI()"
              v-if="surveyQuestions.length != 0"
              class="border-0"
            >
              <div class="closeBtn mb-2 text-right">
                <p @click="closeCurrentTab()" class="btn m-0">
                  <i class="fas fa-window-close fa-4x text-danger"></i>
                </p>
              </div>
              <h6 style="font-size: 24px; font-weight: 600; text-align: center">
                Add Questions for:
                <span class="text-info">{{ survey.name }}</span>
              </h6>
              <div class="questions">
                <div
                  class="question border border-info rounded mt-2 p-3 bg-light"
                  v-for="(question, questionIndex) in surveyQuestions"
                  :key="questionIndex"
                >
                  <b-overlay
                    :show="loadQuestion"
                    spinner-variant="primary"
                    spinner-type="grow"
                    spinner-small
                    rounded="md"
                  >
                    <div v-if="question.disabled">
                      <div class="question d-flex">
                        <div class="questionNo">
                          <p style="font-size: 16px; font-weight: 600">
                            {{ question.question_no }}:
                          </p>
                        </div>
                        <div class="question">
                          <p
                            class="ml-2"
                            style="font-size: 16px; font-weight: 600"
                          >
                            {{ question.name }}
                          </p>
                        </div>
                        <div class="ml-auto" v-if="question.questionId != ''">
                          <b-button
                            variant="info"
                            style="font-size: 12px"
                            size="sm"
                            v-if="question.disabled == true"
                            @click="editSurveyQuestion(questionIndex)"
                            >Edit</b-button
                          >
                          <span
                            class="btn text-danger p-0 m-0 ml-2"
                            @click="
                              markQuestionToBeDeleted(
                                question.questionId,
                                questionIndex
                              )
                            "
                          >
                            <i class="fas fa-trash"></i>
                          </span>
                        </div>
                        <b-button
                          variant="info"
                          style="font-size: 12px"
                          size="sm"
                          v-if="question.disabled == false"
                          @click="
                            prepareSurveyQuestionsForUpdating(questionIndex)
                          "
                          >Save</b-button
                        >
                      </div>
                      <div class="choices">
                        <div
                          class="choice w-100 d-flex flex-wrap align-items-baseline"
                          v-if="question.choicesFromApi.length != 0"
                        >
                          <h6 style="font-size: 16px; font-weight: 600">
                            Choices:
                          </h6>
                          <p
                            v-for="(choice,
                            choiceIndex) in question.choicesFromApi"
                            :key="choiceIndex"
                            style="font-size: 12px; font-weight: 600"
                            class="bg-info p-1 text-white rounded ml-2"
                            :id="'choiceAndScore' + questionIndex"
                          >
                            {{ choice.choice }}
                          </p>
                          <b-tooltip
                            :target="'choiceAndScore' + questionIndex"
                            triggers="hover"
                            placement="top"
                          >
                            <p>Option-Score</p>
                          </b-tooltip>
                        </div>
                      </div>
                      <div
                        class="mt-2 courseOutcome"
                        v-if="survey.survey_type == 'course_exit'"
                      >
                        <!-- <b-form-select
                          :options="courseOutcomesOptions"
                          v-model="question.course_outcome_id"
                          disabled
                          class="w-50"
                        ></b-form-select> -->
                        <multiselect
                          v-model="question.course_outcomes"
                          :options="courseOutcomesOptions"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          placeholder="Select Course outcomes"
                          label="text"
                          track-by="value"
                          :preselect-first="false"
                          disabled
                        >
                        </multiselect>
                      </div>
                      <div
                        class="mt-2 popso"
                        

                        v-if="survey.survey_type == 'programme_exit'||survey.survey_type == 'alumini' ||
            survey.survey_type == 'employer' ||
            survey.survey_type == 'parent' ||
            survey.survey_type == 'faculty'"
                      >
                        <!-- <b-form-select
                          :options="coursePoAndPosOptions"
                          v-model="question.poOrPso"
                          disabled
                          class="w-50"
                        ></b-form-select> -->
                        <multiselect
                          v-if="question.programme_outcomes.length > 0"
                          v-model="question.programme_outcomes"
                          :options="poOptions"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          placeholder="Select Programme outcomes"
                          label="text"
                          track-by="value"
                          :preselect-first="false"
                          disabled
                        >
                        </multiselect>
                        <multiselect
                          v-if="question.programme_specificoutcomes.length > 0"
                          v-model="question.programme_specificoutcomes"
                          :options="psoOptions"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          placeholder="Select Programme specific outcomes"
                          label="text"
                          track-by="value"
                          :preselect-first="false"
                          disabled
                        >
                        </multiselect>
                      </div>
                      <div class="questionOptions d-flex">
                        <div
                          class="questionOptions d-flex justify-content-center mt-2"
                        >
                          <div :id="'answerFieldAllowed' + questionIndex">
                            <b-form-checkbox
                              size="sm"
                              v-model.trim="question.allow_descriptive"
                              disabled
                              ><span
                                style="font-size: 12px; font-weight: 600"
                                :class="
                                  question.allow_descriptive
                                    ? 'text-success'
                                    : 'text-danger'
                                "
                                >Allow Text Field</span
                              ></b-form-checkbox
                            >
                          </div>

                          <div>
                            <b-form-checkbox
                              size="sm"
                              v-model.trim="question.answer_required"
                              disabled
                              class="ml-3"
                              ><span
                                style="font-size: 12px; font-weight: 600"
                                :class="
                                  question.answer_required
                                    ? 'text-success'
                                    : 'text-danger'
                                "
                                >Answer Mandatory</span
                              ></b-form-checkbox
                            >
                          </div>

                          <b-tooltip
                            :target="'answerFieldAllowed' + questionIndex"
                            triggers="hover"
                            placement="bottom"
                          >
                            <p>
                              {{
                                question.allow_descriptive
                                  ? "Input Field allowed for providing feedback while answering questions."
                                  : "Input Field not allowed for providing feedback while answering questions."
                              }}
                            </p>
                          </b-tooltip>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="questionNo d-flex justify-content-between">
                        <b-form-input
                          style="width: 100px; text-align: center"
                          placeholder="Q No."
                          v-model="question.question_no"
                          :class="{
                            'form-group-error':
                              $v.surveyQuestions.$each[questionIndex]
                                .question_no.$error,
                          }"
                          :disabled="question.disabled"
                        ></b-form-input>
                        <div
                          class="deleteQuestion"
                          v-if="question.questionId == ''"
                        >
                          <span
                            class="btn p-0 m-0 text-danger"
                            @click="surveyQuestions.splice(questionIndex, 1)"
                            ><i class="fas fa-minus-circle"></i
                          ></span>
                        </div>
                        <div v-if="question.questionId != ''">
                          <b-button
                            variant="info"
                            style="font-size: 12px"
                            size="sm"
                            v-if="question.disabled == true"
                            @click="editSurveyQuestion(questionIndex)"
                            >Edit</b-button
                          >
                          <b-button
                            variant="info"
                            style="font-size: 12px"
                            size="sm"
                            v-if="question.disabled == false"
                            @click="
                              prepareSurveyQuestionsForUpdating(questionIndex)
                            "
                            >Save</b-button
                          >
                        </div>
                      </div>
                      <span
                        class="input-error-message"
                        v-if="
                          !$v.surveyQuestions.$each[questionIndex].question_no
                            .required &&
                            $v.surveyQuestions.$each[questionIndex].question_no
                              .$dirty
                        "
                        >Question Number is mandatory!</span
                      >
                      <div class="question mt-2">
                        <b-form-input
                          style="text-align: center"
                          placeholder="Question"
                          v-model="question.name"
                          :class="{
                            'form-group-error':
                              $v.surveyQuestions.$each[questionIndex].name
                                .$error,
                          }"
                          :disabled="question.disabled"
                        ></b-form-input>
                      </div>
                      <span
                        class="input-error-message"
                        v-if="
                          !$v.surveyQuestions.$each[questionIndex].name
                            .required &&
                            $v.surveyQuestions.$each[questionIndex].name.$dirty
                        "
                        >Question is mandatory!</span
                      >

                      <!-- choice input options  -->
                      <div class="choices mt-2">
                        <div class="existingChoices mt-2">
                          <div
                            class="choice w-100"
                            v-if="question.choicesFromApi.length != 0"
                          >
                            <h6 style="font-size: 16px; font-weight: 600">
                              Choices:
                            </h6>

                            <div class="d-flex">
                              <div
                                v-for="(choice,
                                choiceIndex) in question.choicesFromApi"
                                :key="choiceIndex"
                                class="d-flex align-items-baseline"
                              >
                                <b-form-input
                                  placeholder="Option A-5"
                                  v-model="choice.choice"
                                  v-if="choice.choiceUpdate"
                                  class="ml-2 mr-2"
                                  v-focus
                                  @blur="updateSurveyChoice(choice.id, choice)"
                                ></b-form-input>
                                <p
                                  v-else
                                  style="font-size: 12px; font-weight: 600"
                                  class="p-1 rounded bg-info ml-2 text-white"
                                  :id="'choiceAndScoreQues' + questionIndex"
                                  @click="
                                    editSurveyChoiceForUpdate(
                                      questionIndex,
                                      choiceIndex
                                    )
                                  "
                                >
                                  {{ choice.choice }}
                                </p>
                                <span
                                  @click="
                                    deleteSurveyChoice(
                                      choice.id,
                                      questionIndex,
                                      choiceIndex
                                    )
                                  "
                                  class="text-danger btn m-0 p-0"
                                >
                                  <i class="fas fa-trash ml-1"></i>
                                </span>
                              </div>
                            </div>

                            <b-tooltip
                              :target="'choiceAndScoreQues' + questionIndex"
                              triggers="hover"
                              placement="top"
                            >
                              <p>Option-Score</p>
                              <p>Click to Edit</p>
                            </b-tooltip>
                          </div>
                        </div>
                        <div class="mt-2">
                          <b-form-tags
                            separator=",;"
                            placeholder="Enter Option-Score pair separated by commas"
                            no-add-on-enter
                            v-model="question.choices"
                            :id="'questionChoices' + questionIndex"
                            :disabled="question.disabled"
                            tag-variant="info"
                            tag-pills
                            size="md"
                            invalid-tag-text="Please enter the correct format"
                            class="text-center"
                            :tag-validator="validateOptionPattern"
                          ></b-form-tags>
                          <b-tooltip
                            :target="'questionChoices' + questionIndex"
                            triggers="hover"
                            placement="top"
                          >
                            <p>
                              Example:
                              Option1-Score1,Option2-Score2,Option3-Score3
                            </p>
                          </b-tooltip>
                        </div>
                      </div>

                      <div
                        class="mt-2 courseOutcome"
                        v-if="survey.survey_type == 'course_exit'"
                      >
                        <!-- <b-form-select
                          :options="courseOutcomesOptions"
                          v-model="question.course_outcome_id"
                          :class="{
                            'form-group-error':
                              $v.surveyQuestions.$each[questionIndex].course_outcome_id
                                .$error,
                          }"
                        ></b-form-select>
                        <span
                          class="input-error-message"
                          v-if="
                            !$v.surveyQuestions.$each[questionIndex].course_outcome_id
                              .required &&
                            $v.surveyQuestions.$each[questionIndex].course_outcome_id
                              .$dirty
                          "
                          >Please Select a course Outcome!</span
                        > -->

                        <!-- <b-form-select
                          :options="courseOutcomesOptions"
                          v-model="question.course_outcome_id"
                        ></b-form-select> -->
                        <multiselect
                          v-model="question.course_outcomes"
                          :options="courseOutcomesOptions"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          placeholder="Select Course outcomes"
                          label="text"
                          track-by="value"
                          :preselect-first="false"
                        >
                        </multiselect>
                      </div>

                      <div
                        class="mt-2 poOrPso"
                        v-if="survey.survey_type == 'programme_exit'||survey.survey_type == 'alumini' ||
            survey.survey_type == 'employer' ||
            survey.survey_type == 'parent' ||
            survey.survey_type == 'faculty'"
                      >
                        <!-- <b-form-select
                          :options="coursePoAndPosOptions"
                          v-model="question.poOrPso"
                          :class="{
                            'form-group-error':
                              $v.surveyQuestions.$each[questionIndex].poOrPso
                                .$error,
                          }"
                        ></b-form-select> -->
                        <multiselect
                          v-model="question.programme_outcomes"
                          :options="poOptions"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          placeholder="Select Programme outcomes"
                          label="text"
                          track-by="value"
                          :preselect-first="false"
                          :disabled="
                            question.programme_specificoutcomes.length > 0
                          "
                        >
                        </multiselect>
                        <multiselect
                          v-model="question.programme_specificoutcomes"
                          :options="psoOptions"
                          :multiple="true"
                          :close-on-select="false"
                          :clear-on-select="false"
                          placeholder="Select Programme specific outcomes"
                          label="text"
                          track-by="value"
                          :preselect-first="false"
                          :disabled="question.programme_outcomes.length > 0"
                        >
                        </multiselect>
                        <!-- <span
                          class="input-error-message"
                          v-if="
                            !$v.surveyQuestions.$each[questionIndex].poOrPso
                              .required &&
                              $v.surveyQuestions.$each[questionIndex].poOrPso
                                .$dirty
                          "
                          >Please Select a PO/PSO!</span
                        > -->
                      </div>

                      <div
                        class="questionOptions d-flex justify-content-center mt-2"
                      >
                        <b-form-checkbox
                          size="sm"
                          v-model.trim="question.allow_descriptive"
                          :disabled="question.disabled"
                          >Allow Text Field</b-form-checkbox
                        >
                        <b-form-checkbox
                          size="sm"
                          v-model.trim="question.answer_required"
                          :disabled="question.disabled"
                          class="ml-3"
                          >Answer Mandatory</b-form-checkbox
                        >
                      </div>
                    </div>
                  </b-overlay>
                </div>
              </div>

              <div v-if="surveyQuestions.length != 0">
                <div class="addQuestion mt-4">
                  <b-button
                    variant="primary"
                    size="sm"
                    :disabled="questionSubmit.disabled"
                    @click="addSurveyQuestion()"
                    >{{ questionSubmit.question }}</b-button
                  >
                </div>
                <div class="submit mt-2 text-center">
                  <b-button
                    type="submit"
                    variant="success"
                    size="md"
                    @click="addQuestion = false"
                    :disabled="
                      questionSubmit.disabled ||
                        surveyQuestions[surveyQuestions.length - 1]
                          .questionId != ''
                    "
                    >{{ questionSubmit.submit }}</b-button
                  >
                </div>
              </div>
            </b-form>
            <br /><br />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  created() {
    this.getSurvey();
  },
  data() {
    return {
      fetchData: false,
      loadQuestion: false,
      loadChoices: false,
      addQuestion: false,
      survey: {},
      surveyQuestionsForm: false,
      surveyQuestions: [],
      surveyQuestionsChoices: [],
      questionSubmit: {
        submit: "Submit",
        question: "Add Question",
        disabled: false,
      },
      responseErrors: {},
      questionId: "",
      courseOutcomesOptions: [],
      // coursePoAndPosOptions: [],
      poOptions: [],
      psoOptions: [],
      questionToBeDeleted: {
        id: null,
        index: null,
      },
    };
  },
  validations: {
    surveyQuestions: {
      required,
      $each: {
        question_no: {
          required,
        },
        name: {
          required,
        },
        // course_outcome_id: {
        //   required: requiredIf(function() {
        //     if (this.survey.survey_type == "course_exit") {
        //       return true;
        //     }
        //   }),
        // },
        // poOrPso: {
        //   required: requiredIf(function() {
        //     if (this.survey.survey_type == "programme_exit") {
        //       return true;
        //     }
        //   }),
        // },
      },
    },
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
  methods: {
    validateOptionPattern(pattern) {
      if (pattern.match(/([a-z]|[0-9])+([-])+([0-9])/g)) {
        return true;
      } else {
        return false;
      }
    },
    markQuestionToBeDeleted(questionId, questionIndex) {
      this.questionToBeDeleted.id = questionId;
      this.questionToBeDeleted.index = questionIndex;
      this.$bvModal.show("deleteSurveyQuestion");
    },
    async deleteSurveyQuestionFromServer() {
      this.$bvModal.hide("deleteSurveyQuestion");
      this.loadQuestion = true;
      const url =
        this.$store.getters.getAPIKey.surveyQuestions +
        `/${this.questionToBeDeleted.id}`;
      await this.$axios
        .delete(url)
        .then((response) => {
          this.$toast.success("Question Deleted", {
            position: "top",
            duration: 3000,
          });
          this.surveyQuestions.splice(this.questionToBeDeleted.index, 1);
          this.loadQuestion = false;
          response;
        })
        .catch((error) => {
          this.loadQuestion = false;
          error;
        });
    },
    //********* Getting Survey Details **********/
    async getSurvey() {
      const surveyId = await this.$route.params.survey_id;
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.studentFeedbackSurvey + `/${surveyId}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.survey = response.data;
          if (this.survey.survey_type == "course_exit") {
            this.getCourseOutcomes();
          }
          if (this.survey.survey_type == 'programme_exit'||
          this.survey.survey_type == 'alumini' ||
          this.survey.survey_type == 'employer' ||
          this.survey.survey_type == 'parent' ||
          this.survey.survey_type == 'faculty') {
            this.getPoAndPos();
          }
          this.getSurveyQuestions();
          response;
        })
        .catch((error) => {
          error;
        });
    },
    //********* Adding Survey Question To Array **********/
    async addSurveyQuestion() {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.surveyQuestions.push({
          questionId: "",
          question_no: "",
          name: "",
          allow_descriptive: false,
          course_outcomes: [],
          programme_specificoutcomes: [],
          programme_outcomes: [],
          // poOrPso: null,
          answer_required: true,
          survey_id: this.survey.id,
          survey_model: "StudFBSurvey",
          grouping_tag: "",
          choices: [],
          choicesFromApi: [],
          disabled: false,
        });
      }
    },
    //********* Prepare Questions **********/
    prepareSurveyQuestions() {
      this.surveyQuestions.push({
        questionId: "",
        question_no: "",
        name: "",
        allow_descriptive: false,
        answer_required: true,
        course_outcomes: [],
        programme_specificoutcomes: [],
        programme_outcomes: [],
        // poOrPso: null,
        survey_id: this.survey.id,
        survey_model: "StudFBSurvey",
        choices: [],
        choicesFromApi: [],
        grouping_tag: "",
        disabled: false,
      });
    },
    //********* Prepare Survey Questions For API **********/
    async prepareSurveyQuestionsForAPI() {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.questionSubmit.submit = "Please wait...";
        this.questionSubmit.question = "Please wait...";
        this.questionSubmit.disabled = true;
        this.loadQuestion = true;

        for (let sQuestion in this.surveyQuestions) {
          if (this.surveyQuestions[sQuestion].questionId == "") {
            let course_outcomes = [];
            await this.surveyQuestions[sQuestion].course_outcomes.forEach(
              (co) => {
                course_outcomes.push(co.value);
              }
            );
            let programme_outcomes = [];
            await this.surveyQuestions[sQuestion].programme_outcomes.forEach(
              (co) => {
                programme_outcomes.push(co.value);
              }
            );
            let programme_specificoutcomes = [];
            await this.surveyQuestions[
              sQuestion
            ].programme_specificoutcomes.forEach((co) => {
              programme_specificoutcomes.push(co.value);
            });

            let surveyQuestion = await {
              question_no: this.surveyQuestions[sQuestion].question_no,
              name: this.surveyQuestions[sQuestion].name,
              allow_descriptive: this.surveyQuestions[sQuestion]
                .allow_descriptive,
              answer_required: this.surveyQuestions[sQuestion].answer_required,
              survey_id: `${this.surveyQuestions[sQuestion].survey_id}`,
              survey_model: this.surveyQuestions[sQuestion].survey_model,
              course_outcomes: course_outcomes,
              programme_specificoutcomes: programme_specificoutcomes,
              programme_outcomes: programme_outcomes,
            };
            const questionCreated = await this.createSurveyQuestion(
              surveyQuestion
            );
            if (questionCreated) {
              this.$toast.success(
                `Question number ${this.surveyQuestions[sQuestion].question_no} created`,
                {
                  position: "top-right",
                  duration: 3000,
                }
              );
              const question = await this.surveyQuestions[sQuestion];
              if (this.surveyQuestions[sQuestion].choices.length != 0) {
                await this.createQuestionChoices(question);
              }

              if (sQuestion == this.surveyQuestions.length - 1) {
                this.$toast.success(`All questions created successfully`, {
                  position: "top",
                  duration: 3000,
                });
                this.clearResponse(true);
              }
            }
            if (!questionCreated) {
              this.$toast.error("Something Went Wrong, please try again!", {
                position: "top",
                duration: 3000,
              });
              this.clearResponse();
            }
          }
        }
      }
    },
    //********* Create Survey Question API **********/
    async createSurveyQuestion(surveyQuestion) {
      const url = this.$store.getters.getAPIKey.surveyQuestions;
      let status = null;
      await this.$axios
        .post(url, surveyQuestion)
        .then((response) => {
          this.questionId = response.data.id;
          status = true;
          response;
        })
        .catch((error) => {
          status = false;
          error;
        });
      return status;
    },
    //********* Create Survey Question Choices **********/
    async createQuestionChoices(question) {
      const questionChoicesPostData = {
        survey_question_choices: [],
      };
      await question.choices.forEach((choice) => {
        const surveyChoice = choice.split("-");
        questionChoicesPostData.survey_question_choices.push({
          name: surveyChoice[0],
          score: surveyChoice[1],
        });
      });

      const url =
        this.$store.getters.getAPIKey.surveyQuestions +
        `/${this.questionId}/surveychoices`;

      await this.$axios
        .post(url, questionChoicesPostData)
        .then((response) => {
          response;
        })
        .catch((error) => {
          error;
        });
    },
    //********* Edit Survey Choice Input For Update */
    editSurveyChoiceForUpdate(questionIndex, choiceIndex) {
      const question = this.surveyQuestions[questionIndex];
      question.choicesFromApi.forEach((choice) => {
        choice.choiceUpdate = false;
      });
      question.choicesFromApi[choiceIndex].choiceUpdate = true;
    },
    //******** Update Survey Choice ********/`
    async updateSurveyChoice(choiceId, choice) {
      if (choice == "") {
        return this.$toast.error("Please check the input", {
          position: "top",
          duration: 3000,
        });
      }
      this.loadQuestion = true;
      const userChoice = await choice.choice.split("-");
      const choiceForApi = { name: userChoice[0], score: userChoice[1] };
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/surveychoices/${choiceId}`;
      await this.$axios
        .post(url, choiceForApi)
        .then((response) => {
          this.$toast.success("Choice Updated", {
            position: "top",
            duration: 3000,
          });
          choice.choiceUpdate = false;
          this.loadQuestion = false;
          response;
        })
        .catch((error) => {
          this.$toast.error("Something went wrong, please try again", {
            position: "top",
            duration: 3000,
          });
          this.loadQuestion = false;
          error;
        });
    },

    //********* Delete SUrvey Choice ***********/
    async deleteSurveyChoice(choiceId, questionIndex, choiceIndex) {
      this.loadQuestion = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/surveychoices/${choiceId}`;
      await this.$axios
        .delete(url)
        .then((response) => {
          this.surveyQuestions[questionIndex].choicesFromApi.splice(
            choiceIndex,
            1
          );
          this.$toast.success("Deleted", {
            position: "top",
            duration: 3000,
          });
          this.loadQuestion = false;
          response;
        })
        .catch((error) => {
          this.loadQuestion = false;
          error;
        });
    },
    //********* Getting All Survey Questions **********/
    getSurveyQuestions() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.studentFeedbackSurvey +
        `/${this.survey.id}/surveyquestions`;
      this.$axios
        .get(url)
        .then((response) => {
          this.surveyQuestions = [];
          const questions = response.data;
          questions;

          questions.forEach((question) => {
            const choicesData = [];
            if (question.choices.length != 0) {
              question.choices.forEach((choice) => {
                choicesData.push({
                  id: choice.id,
                  choice: choice.name + " - " + Math.floor(choice.score),
                  updatedChoice: "",
                  choiceUpdate: false,
                });
              });
            }
            let questionPos = [];
            question.programme_outcomes.forEach((po) => {
              questionPos.push({ value: po.id, text: po.code });
            });
            let questionPsos = [];
            question.programme_specific_outcomes.forEach((pso) => {
              questionPsos.push({ value: pso.id, text: pso.code });
            });
            let questionCos = [];
            question.course_outcomes.forEach((co) => {
              questionCos.push({ value: co.id, text: co.code });
            });
            // if (question.programme_outcomes.length) {
            //   poOrPso["PO"] = question.programme_outcomes;
            // }
            // if (question.programme_specificoutcomes.length) {
            //   poOrPso["PSO"] = question.programme_specificoutcomes;
            // }
            // if (
            //   question.programme_outcomes == null &&
            //   question.programme_specificoutcomes == null
            // ) {
            //   poOrPso = null;
            // }
            this.surveyQuestions.push({
              questionId: question.id,
              question_no: question.question_no,
              name: question.name,
              allow_descriptive: question.allow_descriptive == 1 ? true : false,
              answer_required: question.answer_required == 1 ? true : false,
              survey_id: question.survey_id,
              survey_model: question.survey_model,
              grouping_tag: question.grouping_tag,
              // poOrPso: poOrPso,
              choices: [],
              course_outcomes: questionCos,
              programme_specificoutcomes: questionPsos,
              programme_outcomes: questionPos,
              updatedChoice: "",
              choiceUpdate: false,
              choicesFromApi: choicesData,
              disabled: true,
            });
          });
          if (this.addQuestion) {
            this.addSurveyQuestion();
          }

          this.fetchData = false;
          this.$forceUpdate();
          response;
        })
        .catch((error) => {
          this.fetchData = false;
          error;
        });
    },
    //********* Edit Survey Question **********/
    editSurveyQuestion(questionIndex) {
      this.questionSubmit.disabled = true;
      const question = this.surveyQuestions[questionIndex];
      this.surveyQuestions.forEach((question) => {
        if (question.questionId != "") {
          question.disabled = true;
        }
      });
      question.disabled = false;
      this.$forceUpdate();
    },
    //********* Prepare Question Data for Update **********/
    async prepareSurveyQuestionsForUpdating(questionIndex) {
      await this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        const question = await this.surveyQuestions[questionIndex];
        this.loadQuestion = true;

        let course_outcomes = [];
        await question.course_outcomes.forEach((co) => {
          course_outcomes.push(co.value);
        });
        let programme_outcomes = [];
        await question.programme_outcomes.forEach((co) => {
          programme_outcomes.push(co.value);
        });
        let programme_specificoutcomes = [];
        await question.programme_specificoutcomes.forEach((co) => {
          programme_specificoutcomes.push(co.value);
        });

        let surveyQuestion = await {
          question_no: question.question_no,
          name: question.name,
          choices: question.choices,
          scores: question.scores,
          allow_descriptive: question.allow_descriptive,
          answer_required: question.answer_required,
          survey_id: `${question.survey_id}`,
          survey_model: question.survey_model,
          course_outcomes: course_outcomes,
          programme_specificoutcomes: programme_specificoutcomes,
          programme_outcomes: programme_outcomes,
        };

        this.questionId = await question.questionId;
        const questionUpdated = await this.updateSurveyQuestion(
          surveyQuestion,
          question.questionId
        );
        if (questionUpdated) {
          this.$toast.success("Question Updated", {
            position: "top",
            duration: 3000,
          });
          if (question.choices.length != 0) {
            await this.createQuestionChoices(question);
          }

          await this.getSurveyQuestions();
          this.loadQuestion = false;
          this.clearResponse();
        }
        if (!questionUpdated) {
          this.$toast.error("Something Went Wrong, please try again!", {
            position: "top",
            duration: 3000,
          });
          this.loadQuestion = false;
          this.clearResponse();
        }
      }
    },
    //********* Update Survey Question API **********/
    async updateSurveyQuestion(surveyQuestion, questionId) {
      const url =
        this.$store.getters.getAPIKey.surveyQuestions + `/${questionId}`;
      let status = null;
      await this.$axios
        .put(url, surveyQuestion)
        .then((response) => {
          status = true;
          response;
        })
        .catch((error) => {
          status = false;
          error;
        });
      return status;
    },
    //********* Get Course Outcomes **********/
    getCourseOutcomes() {
      let outcomeURL = this.$store.getters.getAPIKey.getCourseOutcomes;
      const url = outcomeURL.replace("course_id", this.survey.course_id);
      this.$axios
        .get(url)
        .then((response) => {
          const outcomes = response.data;
          this.courseOutcomesOptions = [
            {
              value: null,
              text:
                outcomes.length == 0 ? "No Outcomes Found" : "Select Outcome",
            },
          ];
          outcomes.forEach((outcome) => {
            this.courseOutcomesOptions.push({
              value: outcome.id,
              text: outcome.code,
            });
          });

          response;
        })
        .catch((error) => {
          error;
        });
    },
    async getPoAndPos() {
      await this.getProgramOutcomes();
      await this.getProgramSpecificOutcomes();
    },

    async getProgramOutcomes() {
      const url = this.$store.getters.getAPIKey.programOutcome+`?usergroup=${this.survey.usersubgroup.usergroup_id}`;
      await this.$axios
        .get(url)
        .then((response) => {
          const programOutcomes = response.data;
          this.poOptions = [
            {
              value: null,
              text: "Select PO",
            },
          ];
          programOutcomes.forEach((po) => {
            this.poOptions.push({
              value: po.id,
              text: po.code,
            });
          });
          response;
        })
        .catch((error) => {
          error;
        });
    },
    async getProgramSpecificOutcomes() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroup/${this.survey.usersubgroup.usergroup_id}/programmespecificoutcomes`;
      await this.$axios
        .get(url)
        .then((response) => {
          const programSpecificOutcomes = response.data;

          this.psoOptions = [
            {
              value: null,
              text: "Select PSO",
            },
          ];
          programSpecificOutcomes.forEach((pso) => {
            this.psoOptions.push({
              value: pso.id,
              text: pso.code,
            });
          });
          response;
        })
        .catch((error) => {
          error;
        });
    },
    //********* Reset Response UI **********/
    clearResponse(status) {
      this.questionSubmit.submit = "Submit";
      this.questionSubmit.question = "Add Question";

      setTimeout(() => {
        if (status) {
          this.closeCurrentTab();
        }
        this.loadQuestion = false;
        this.questionSubmit.disabled = false;
        this.responseErrors = {};
      }, 3000);
    },
  },
};
</script>
